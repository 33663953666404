import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  LockIcon,
  SwapFillIcon,
  EarnFillIcon,
  CommunityFilledIcon,
  EarnIcon,
  BackForwardIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  CommunityIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import { SubSwapName } from 'config/constants/subswap'
import { BASE_S3 } from 'config'
import { SubSwapType, LogoSuffix } from 'config/constants/exchange'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

// 修改点：logo图片

const switchLogoIcon = () => {
  if (LogoSuffix[SubSwapName as SubSwapType] === undefined) {
    return `${BASE_S3}/images/main/logoicon.png`
  }
  return `${BASE_S3}/images/main/logoicon-${LogoSuffix[SubSwapName as SubSwapType]}.png`
}

const switchLogoText = () => {
  if (LogoSuffix[SubSwapName as SubSwapType] === undefined) {
    return `${BASE_S3}/images/main/logo.png`
  }
  return `${BASE_S3}/images/main/logo-${LogoSuffix[SubSwapName as SubSwapType]}.png`
}

export const logoConfig = {
  logoSrc: switchLogoIcon(),
  logoWithTextSrc: switchLogoText(),
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Swap'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Swap V3'),
          href: 'https://swapv3.swapxbit.com/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Guidebook'),
    //   icon: CommunityIcon,
    //   fillIcon: CommunityFilledIcon,
    //   href: 'https://introduce.5bitchain.com/',
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Bridge'),
    //   href: 'https://newbridge.bitchain.biz',
    //   icon: BackForwardIcon,
    //   fillIcon: BackForwardIcon,
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('Liquidity'),
      href: '/liquidity',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      showItemsOnMobile: false,
      image: 'https://dql4d4zhzt5hs.cloudfront.net/images/decorations/pe2.png',
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },

    // {
    //   label: t('AITRADINGS'),
    //   href: `https://aitradings.momorobot.org/`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   showItemsOnMobile: false,

    //   items: [],
    // },
    // {
    //   label: '',
    //   href: '',
    //   icon: MoreIcon,
    //   hideSubNav: true,
    //   items: [
    //     {
    //       label: t('LOCK'),
    //       href: 'https://www.maplesales.org/mapLock/create',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('AITRADINGS'),
    //       href: 'https://aitradings.momorobot.org/',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
